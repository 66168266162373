import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ReactStars from 'react-rating-stars-component';
import Slider from 'react-slick';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import formatString from 'format-string-by-pattern';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import {
  composeValidators,
  email,
  renderField,
  required,
  usPhoneNumber
} from '../helpers/form-validations';
import {
  callFBFunction,
  getAllReviewsByStaff,
  getReferById,
  getRoofTopById,
  getStaffById,
  getTestimonialByUserId,
  getUserById,
  getUserFeedByUserIdAndFeedType,
  getVehiclesByUserId
} from '../utils/firebase';
import SalesContactCard from '../components/ReferralHome/SalesContactCard';
import RooftopDetailCard from '../components/Dashboard/RooftopDetailCard';
import appImage from '../assets/image';
import { eventReactGTMModuleReferral } from '../utils/analytics';

const share = {
  href: '#'
};

const sliderSettings = {
  className: 'referralSlider',
  speed: 100,
  slidesToShow: 2,
  slidesToScroll: 2,
  arrows: true,
  infinite: true,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const ReferralHome = ({ setLoader, setNotify }) => {
  const { userId, referCode } = useParams();
  const refVideo = useRef(null);
  const [user, setUser] = useState({});
  const [rooftop, setRooftop] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [userFeed, setUserFeed] = useState({});
  const [referral, setReferral] = useState({});
  const [isEmail, setIsEmail] = useState(false);
  const [staff, setStaff] = useState({});
  const [reviews, setReview] = useState([]);
  const [video, setVideo] = useState(null);

  const onSubmit = (formData, form) => {
    const payload = {
      ...formData,
      refer: referCode === undefined ? '' : referCode,
      parentUser: userId,
      rooftop: user.rooftop,
      sales: user.staff,
      selectedImage: ''
    };
    setLoader(true);
    callFBFunction('createReferVinsyt', payload).then(async (response) => {
      const {
        data: { status, message }
      } = response;
      if (status) {
        setNotify({ message, type: 'success' });
        form.reset();
        form.resetFieldState('email');
        form.resetFieldState('phone');
        form.resetFieldState('firstName');
        form.resetFieldState('lastName');
        form.resetFieldState('vin');
      } else {
        setNotify({ message, type: 'error' });
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    async function onInit() {
      setLoader(true);
      const userRes = await getUserById(userId);
      if (isEmpty(userRes)) {
        window.location = '/';
      }
      const roofTopRes = await getRoofTopById(userRes.rooftop);
      setRooftop(roofTopRes);

      if (Object.prototype.hasOwnProperty.call(userRes, 'disabled') && userRes.disabled) {
        window.location = roofTopRes.url.main || '/';
      }
      setUser(userRes);
      const staffData = await getStaffById(userRes.staff);
      setStaff(staffData);
      const testimonialData = await getTestimonialByUserId(userId);
      setVideo(testimonialData);
      const reviewsLists = await getAllReviewsByStaff(staffData.id);
      setReview(reviewsLists);

      const vehicleRes = await getVehiclesByUserId(userId);
      setVehicle(vehicleRes[0]);
      const userFeedRes = await getUserFeedByUserIdAndFeedType({
        userId,
        feedType: 'welcome'
      });
      setUserFeed(userFeedRes);

      if (referCode !== undefined) {
        const referralRes = await getReferById(referCode);
        if (!isEmpty(referralRes)) {
          referralRes.first = referralRes.name.first;
          referralRes.last = referralRes.name.last;
          setReferral(referralRes);
        }
      }
      setLoader(false);
    }
    onInit();
  }, []);

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }
    if (video) {
      refVideo.current.defaultMuted = true;
      refVideo.current.muted = true;
    }
  }, [video]);

  const gaEventData = (data, link = false) => {
    eventReactGTMModuleReferral({
      ownerID: userId,
      agentID: user.staff,
      rooftop: user.rooftop,
      dealerGroupID: rooftop.group,
      ...data
    });
    if (link) window.open(link);
  };

  return (
    <>
      <main className="lg:col-span-12 xl:col-span-9">
        {video && video.link && (
          <div className="bg-gray-800 rounded-lg mb-8 overflow-hidden">
            <video
              ref={refVideo}
              controls
              autoPlay
              playsInline
              className="w-[100%]"
              src={video.link}
              alt="video_testimonial"
            />
          </div>
        )}
        <div className="relative bg-gray-800 rounded-lg">
          <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 rounded-t-lg md:rounded-tr-none md:rounded-l-lg">
            <img
              className="w-full h-full object-cover print:rounded rounded-t-lg md:rounded-tr-none md:rounded-l-lg"
              src={userFeed.feed_image}
              alt=""
            />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                EXCITING NEWS
              </h2>
              <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                {!isEmpty(user) && user.name.first} got a new{' '}
                {!isEmpty(vehicle) && vehicle.attributes.make}{' '}
                {!isEmpty(vehicle) && vehicle.attributes.model}
              </p>
              <p className="mt-3 text-lg text-gray-300">
                Heads up! {!isEmpty(user) && user.name.first} {!isEmpty(user) && user.name.last} is
                the proud owner of a {!isEmpty(vehicle) && vehicle.attributes.make}{' '}
                {!isEmpty(vehicle) && vehicle.attributes.model} and wanted to share the love.
              </p>
              <p className="mt-3 text-lg text-gray-300">
                {' '}
                {referCode !== undefined && !isEmpty(rooftop) && rooftop.refer.they_get
                  ? 'Check out the special gift they sent below.'
                  : !isEmpty(staff) &&
                    `Get the same experience from ${staff.name.first}. Follow the links below.`}
              </p>
              <div className="mt-8">
                <div>
                  <ChevronDownIcon className="h-10 w-10 text-gray-700" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isEmpty(staff) && !isEmpty(rooftop) && (
          <div className="flex flex-col md:flex-row pt-10 gap-0 md:gap-4 lg:gap-8">
            <div className="flex-auto md:flex-1 bg-white rounded-lg shadow m-6 md:m-0">
              <SalesContactCard
                staff={staff}
                rooftop={rooftop}
                reviews={reviews.length}
                gaEventData={gaEventData}
              />
            </div>
            <div className="flex-auto md:flex-1 bg-white rounded-lg shadow m-6 md:m-0">
              <RooftopDetailCard isReferralHome gaEventData={gaEventData} />
            </div>
          </div>
        )}

        {!isEmpty(staff) && !isEmpty(reviews) && (
          <div
            className="pt-8 mx-6 md:mx-0"
            style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
            <div className="flex flex-1 items-center border-b border-gray-200 my-4">
              <nav className="flex-1 -mb-px flex space-x-6 xl:space-x-8" aria-label="Tabs">
                <span className="text-gray-700 border-gray-300 cursor-pointer whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm">
                  {staff.name.first} {staff.name.last}'s Reviews
                </span>
              </nav>
            </div>
            <div>
              {reviews.length > 1 ? (
                <Slider {...sliderSettings}>
                  {reviews.map((item, key) => (
                    <div
                      className="w-full flex items-center justify-between space-x-6 review-card-inline"
                      key={key}>
                      <img
                        className="w-9 h-9 bg-gray-300 self-start rounded-full flex-shrink-0"
                        src={item.avatar}
                        alt=""
                      />
                      <div className="flex-1">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-gray-900 text-sm">
                            {item.name.first} {item.name.last}
                          </h3>
                        </div>
                        <div className="flex items-center space-x-3">
                          <p className="text-gray-500 text-sm">
                            {moment.unix(item.created_on.seconds).format('MMMM DD, YYYY')}
                          </p>
                        </div>
                        <div className="flex-1 py-5">
                          <div className="flex items-center gap-1">
                            <ReactStars
                              size={20}
                              count={5}
                              color="grey"
                              activeColor="#FACC14"
                              value={item.rating}
                              isHalf
                              edit={false}
                            />
                          </div>
                          <div className="flex w-[288px] items-center pt-3">
                            <div
                              className="text-gray-900 text-sm whitespace-normal "
                              dangerouslySetInnerHTML={{ __html: item.comment }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                reviews.map((item, key) => (
                  <div
                    className="w-full flex items-center justify-between space-x-6 review-card-inline"
                    key={key}>
                    <img
                      className="w-9 h-9 bg-gray-300 self-start rounded-full flex-shrink-0"
                      src={item.avatar}
                      alt=""
                    />
                    <div className="flex-1">
                      <div className="flex items-center space-x-3">
                        <h3 className="text-gray-900 text-sm">
                          {item.name.first} {item.name.last}
                        </h3>
                      </div>
                      <div className="flex items-center space-x-3">
                        <p className="text-gray-500 text-sm">
                          {moment.unix(item.created_on.seconds).format('MMMM DD, YYYY')}
                        </p>
                      </div>
                      <div className="flex-1 py-5">
                        <div className="flex items-center gap-1">
                          <ReactStars
                            size={20}
                            count={5}
                            color="grey"
                            activeColor="#FACC14"
                            value={item.rating}
                            isHalf
                            edit={false}
                          />
                        </div>
                        <div className="flex w-[288px] items-center pt-3">
                          <div
                            className="text-gray-900 text-sm whitespace-normal "
                            dangerouslySetInnerHTML={{ __html: item.comment }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        )}

        {referCode !== undefined && !isEmpty(rooftop) && rooftop.refer.they_get > '0' && (
          <div className="relative bg-gray-50 overflow-hidden print:mt-0 mt-14 rounded-lg">
            <div
              className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
              aria-hidden="true">
              <div className="relative h-full max-w-7xl mx-auto">
                <svg
                  className="absolute right-full transform print:translate-y-20	translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784">
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse">
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={784}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784">
                  <defs>
                    <pattern
                      id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse">
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={784}
                    fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
                  />
                </svg>
              </div>
            </div>

            <div className="relative print:pt-0 pt-6 print:pb-2 pb-16 sm:pb-24">
              <main className="print:mt-0 mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
                <div className="text-center">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block text-indigo-600 xl:inline">
                      ${!isEmpty(rooftop) && rooftop.refer.they_get} off
                    </span>{' '}
                    <span className="block xl:inline"> your next car</span>
                  </h1>
                  <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                    {!isEmpty(user) && user.name.first} sent you this voucher worth $
                    {!isEmpty(rooftop) && rooftop.refer.they_get} off any new vehicle purchase at{' '}
                    {!isEmpty(rooftop) && rooftop.name}.
                  </p>
                  <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                    Present this page to your sales agent at the time of sale. It’s that simple.
                  </p>
                  <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                    <div className="rounded-md shadow">
                      <a
                        href={share.href}
                        onClick={() => window.print()}
                        className="print:hidden w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                        Print your Voucher
                      </a>
                    </div>
                  </div>
                  <p className="max-w-md mx-auto text-gray-500 md:mt-5 text-sm md:max-w-3xl">
                    *Restrictions Apply
                  </p>
                </div>
              </main>
            </div>
          </div>
        )}
        {!isEmpty(rooftop) && (
          <div className="flex flex-col md:flex-row pt-10 gap-x-3 lg:gap-x-6">
            {!isEmpty(rooftop.external_links.service) && (
              <div
                className="cursor-pointer m-6 md:m-0 flex-1 bg-white rounded-lg shadow md:max-w-[210px]"
                onClick={() =>
                  gaEventData(
                    {
                      event: 'e_schedule_appointment',
                      area: 'link_grid',
                      external_destination: 'service'
                    },
                    rooftop.external_links.service
                  )
                }>
                <div className="flex flex-col p-5 justify-center items-center">
                  <p className="text-center">Schedule Service</p>
                  <img src={appImage.scheduleService} alt="" />
                </div>
              </div>
            )}
            {!isEmpty(rooftop.external_links.new_inventory) && (
              <div
                className="cursor-pointer m-6 md:m-0 flex-1 bg-white rounded-lg shadow md:max-w-[210px]"
                onClick={() =>
                  gaEventData(
                    {
                      event: 'e_dealer_visit',
                      area: 'link_grid',
                      external_destination: 'service'
                    },
                    rooftop.external_links.new_inventory
                  )
                }>
                <div className="flex flex-col p-5 justify-center items-center">
                  <p className="text-center">Shop Inventory</p>
                  <img src={appImage.shopInventory} alt="" />
                </div>
              </div>
            )}
            {!isEmpty(rooftop.external_links.incentives) && (
              <div
                className="cursor-pointer m-6 md:m-0 flex-1 bg-white rounded-lg shadow md:max-w-[210px]"
                onClick={() =>
                  gaEventData(
                    {
                      event: 'e_dealer_visit',
                      area: 'link_grid',
                      external_destination: 'incentives'
                    },
                    rooftop.external_links.incentives
                  )
                }>
                <div className="flex flex-col p-5 justify-center items-center">
                  <p className="text-center">Latest Incentives</p>
                  <img src={appImage.latestIncentives} alt="" />
                </div>
              </div>
            )}
            {!isEmpty(rooftop.external_links.get_pre_approved_url) && (
              <div
                className="cursor-pointer m-6 md:m-0 flex-1 bg-white rounded-lg shadow md:max-w-[210px]"
                onClick={() =>
                  gaEventData(
                    {
                      event: 'e_dealer_visit',
                      area: 'link_grid',
                      external_destination: 'finance'
                    },
                    rooftop.external_links.get_pre_approved_url
                  )
                }>
                <div className="flex flex-col p-5 justify-center items-center">
                  <p className="text-center">Get Pre-Approved</p>
                  <img src={appImage.getPreApproved} alt="" />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="print:hidden py-10 m-6 md:m-0">
          <div className="bg-gradient-to-b from-[#5046E4] to-[#323379] rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="pt-10 pb-12 px-6 sm:pt-16 md:px-16 lg:py-16 lg:pr-0 xl:py-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">Get your own Vinsyt</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-indigo-200">
                  Vinsyt connects you to a world of information about your vehicle and makes
                  ownership fun.
                </p>
                <p className="text-lg leading-6 text-indigo-200">Try it! It's free forever.</p>
                <div className="mt-6">
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="w-full mb-1">
                          <label
                            htmlFor="inline-vin"
                            className="block text-sm font-medium text-white">
                            VIN
                          </label>
                          <div className="mt-1">
                            <Field
                              className="appearance-none w-full block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              name="vin"
                              type="text"
                              id="inline-vin"
                              placeholder="3GNCJPSB3KL273514"
                              validate={required}>
                              {renderField}
                            </Field>
                          </div>
                        </div>

                        <div>
                          <div className="w-full mb-1">
                            <label
                              htmlFor="inline-email"
                              className="block text-sm font-medium text-white">
                              Email
                            </label>
                            <div className="mt-1 mt-3">
                              <Field
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                name="email"
                                type="text"
                                id="inline-email"
                                placeholder="email@example.com"
                                defaultValue={referral.email}
                                validate={
                                  !isEmail
                                    ? composeValidators(email)
                                    : composeValidators(required, email)
                                }>
                                {renderField}
                              </Field>
                            </div>
                            <OnChange name="email">
                              {(value) => {
                                setIsEmail(value !== '');
                              }}
                            </OnChange>
                          </div>
                          <div className="w-full mb-1 mt-3">
                            <label
                              htmlFor="inline-phone"
                              className="block text-sm font-medium text-white">
                              Phone
                            </label>
                            <div className="mt-1 mt-3">
                              <Field
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                name="phone"
                                type="text"
                                id="inline-phone"
                                parse={formatString('(999) 999-9999')}
                                placeholder="(123) 456-7890"
                                defaultValue={referral.phone}
                                validate={
                                  isEmail
                                    ? composeValidators(usPhoneNumber)
                                    : composeValidators(required, usPhoneNumber)
                                }>
                                {renderField}
                              </Field>
                            </div>
                            <OnChange name="phone">
                              {(value) => {
                                setIsEmail(value === '');
                              }}
                            </OnChange>
                          </div>
                          <div className="w-full mt-3">
                            <label
                              htmlFor="inline-firstname"
                              className="block text-sm font-medium text-white">
                              First
                            </label>
                            <div className="mt-1">
                              <Field
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                name="firstName"
                                type="text"
                                id="inline-firstname"
                                placeholder="Jeff"
                                defaultValue={referral.first}
                                validate={required}>
                                {renderField}
                              </Field>
                            </div>
                          </div>
                          <div className="w-full mb-1 mt-3">
                            <label
                              htmlFor="inline-lastname"
                              className="block text-sm font-medium text-white">
                              Last
                            </label>
                            <div className="mt-1">
                              <Field
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                name="lastName"
                                type="text"
                                id="inline-lastname"
                                placeholder="Smith"
                                defaultValue={referral.last}
                                validate={required}>
                                {renderField}
                              </Field>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end mt-10">
                          <button
                            className="shadow text-white bg-indigo-600 hover:bg-indigo-800 focus:shadow-outline focus:outline-none py-2 px-4 rounded"
                            type="submit">
                            Create
                          </button>
                        </div>
                      </form>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <img
                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                src="https://firebasestorage.googleapis.com/v0/b/vinsyt-prod.appspot.com/o/app%2Flarge-Home.png?alt=media&token=57479c9a-9513-4c79-aa9e-d842848ec661"
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default withLoader(withNotify(ReferralHome));
